import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" >
<path d="M4260 10064 c0 -17 97 -115 170 -172 42 -33 52 -44 51 -56 0 -3 59
-62 132 -132 73 -70 178 -173 234 -230 l103 -104 1627 0 c896 -1 1647 -5 1671
-9 52 -10 115 -53 142 -96 20 -33 20 -46 18 -1116 -2 -596 -5 -1091 -8 -1099
-3 -8 -9 -20 -15 -26 -5 -6 -44 -51 -85 -99 -41 -49 -94 -108 -117 -132 -24
-24 -43 -49 -43 -55 0 -6 -13 -24 -30 -40 -16 -15 -30 -32 -30 -37 0 -4 -25
-34 -55 -66 -30 -32 -55 -63 -55 -68 0 -5 -34 -43 -75 -85 -41 -42 -75 -79
-75 -84 0 -4 -13 -22 -30 -40 -144 -160 -236 -275 -227 -285 4 -3 100 -5 214
-5 242 2 233 -2 233 101 0 100 31 158 102 195 49 25 63 28 112 23 64 -6 99
-28 148 -89 l33 -42 5 -651 5 -650 348 -3 347 -2 0 2569 c0 1413 -3 2577 -6
2585 -6 15 -209 16 -2375 16 -1342 -1 -2371 -5 -2374 -10 -3 -5 -19 -5 -38 1
-45 13 -57 11 -57 -7z m3657 -3939 c2 -6 -6 -16 -17 -23 -17 -10 -23 -9 -40 8
-11 11 -20 23 -20 26 0 10 73 -1 77 -11z"/>
<path d="M3947 9774 c-4 -4 -7 -1095 -7 -2424 0 -2289 1 -2418 18 -2432 13
-12 40 -15 129 -13 l113 3 41 44 c22 23 55 63 71 88 17 25 46 61 64 80 62 67
111 125 120 142 5 9 39 48 77 88 37 39 67 74 67 77 0 5 13 21 90 113 19 23 41
52 48 66 7 13 19 24 27 24 8 0 15 6 15 13 0 7 19 34 43 58 71 75 131 149 146
180 8 16 18 29 23 29 4 0 18 12 31 27 12 15 43 50 70 79 26 28 47 55 47 60 0
5 17 28 38 51 20 23 51 58 67 78 17 20 41 49 55 65 14 16 41 50 60 75 19 25
53 65 75 89 133 142 255 288 255 305 0 3 -213 7 -474 8 -413 1 -478 4 -505 18
-17 8 -31 20 -31 25 0 5 -10 19 -22 30 l-23 21 -5 1083 c-4 915 -7 1086 -19
1100 -12 14 -12 17 0 14 7 -2 15 6 17 17 2 14 -20 41 -74 90 -42 39 -82 76
-88 83 -9 12 -161 172 -191 201 -99 100 -232 221 -242 221 -7 0 -19 15 -27 34
-8 19 -25 40 -37 47 -13 6 -30 21 -39 31 -8 11 -19 16 -23 12z m788 -3086 c6
-4 2 -8 -7 -8 -10 0 -18 7 -18 17 0 9 3 12 8 7 4 -5 12 -12 17 -16z m-458
-1494 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M5064 8947 c-3 -8 -4 -413 -2 -901 3 -768 5 -888 18 -896 17 -10 673
-10 690 0 8 5 12 147 13 491 l2 484 30 42 c19 27 49 51 85 68 l55 26 600 -3
600 -4 46 -27 c85 -50 94 -66 94 -170 0 -92 0 -93 -40 -146 -22 -30 -54 -68
-70 -85 -55 -57 -125 -143 -125 -154 0 -6 -12 -20 -27 -32 -16 -12 -32 -30
-38 -40 -8 -15 -64 -81 -125 -149 -8 -9 -33 -39 -54 -66 -22 -28 -60 -73 -85
-101 -25 -28 -57 -64 -70 -80 -13 -16 -52 -60 -86 -99 -34 -38 -73 -86 -86
-105 -13 -20 -38 -51 -56 -70 -82 -91 -113 -128 -113 -134 0 -4 -21 -30 -47
-57 -26 -28 -54 -61 -63 -73 -8 -13 -39 -49 -67 -80 -29 -31 -53 -60 -53 -65
0 -8 -26 -38 -108 -126 -21 -22 -53 -60 -72 -85 -34 -44 -80 -98 -111 -129 -8
-9 -44 -52 -80 -96 -76 -95 -107 -132 -134 -159 -11 -12 -32 -38 -45 -58 -14
-21 -28 -38 -33 -38 -4 0 -20 -19 -35 -42 -15 -24 -41 -57 -57 -74 -17 -18
-46 -51 -65 -74 -19 -24 -43 -51 -52 -61 -10 -11 -18 -21 -18 -24 0 -2 -11
-15 -25 -29 -14 -14 -25 -32 -25 -40 0 -8 -9 -17 -20 -21 -11 -3 -29 -21 -42
-38 -12 -18 -42 -57 -67 -87 -26 -30 -53 -62 -61 -72 -8 -10 -20 -18 -26 -18
-7 0 -24 -21 -39 -46 -15 -25 -43 -63 -64 -84 -54 -56 -74 -81 -100 -122 -12
-21 -26 -38 -30 -38 -4 0 -13 -11 -19 -25 -10 -21 -9 -25 6 -25 1188 -2 3227
1 3231 6 3 3 7 165 9 360 3 334 2 354 -15 355 -10 1 -237 2 -505 3 l-487 1
-39 38 c-49 48 -70 98 -66 164 3 65 19 94 93 170 34 35 61 66 61 69 0 4 10 17
23 29 36 37 69 78 91 115 11 19 45 59 75 89 29 29 83 90 119 134 36 45 73 88
81 97 28 28 114 130 130 153 8 12 17 24 21 27 3 3 21 25 40 50 19 25 43 54 55
65 11 11 36 40 55 65 19 24 49 60 67 79 18 20 59 70 92 111 33 41 70 86 83
100 l23 25 3 840 c1 462 0 855 -3 873 l-6 32 -1465 0 c-1208 0 -1466 -2 -1470
-13z m2266 -2315 c0 -4 -9 -13 -20 -20 -18 -11 -19 -10 -8 13 10 21 28 26 28
7z"/>
<path d="M4967 4256 c-41 -15 -106 -57 -132 -86 -15 -17 -15 -19 4 -37 11 -10
36 -27 56 -37 30 -16 40 -18 53 -8 81 59 177 67 219 17 27 -31 29 -64 7 -110
-13 -29 -273 -286 -310 -308 -9 -5 -14 -27 -14 -62 l0 -55 260 0 260 0 0 65 0
65 -142 0 -143 0 110 109 c130 129 155 170 155 253 0 139 -85 208 -255 207
-50 0 -107 -6 -128 -13z"/>
<path d="M3195 4247 c-3 -6 -4 -161 -3 -342 l3 -330 83 -3 82 -3 0 346 0 345
-80 0 c-56 0 -82 -4 -85 -13z"/>
<path d="M3449 4255 c0 -3 -1 -31 -1 -62 l-1 -58 109 -5 109 -5 3 -277 2 -278
80 0 80 0 2 278 3 277 108 3 107 3 0 64 0 65 -300 0 c-165 0 -300 -2 -301 -5z"/>
<path d="M4204 3924 c-82 -185 -151 -340 -152 -345 -3 -6 30 -9 79 -7 l84 3
25 63 c14 35 32 68 41 72 9 5 82 8 164 7 l149 -2 30 -72 30 -73 83 0 c46 0 83
2 83 5 0 2 -28 66 -61 142 -99 220 -152 341 -198 446 l-43 97 -82 0 -82 0
-150 -336z m289 39 c26 -62 47 -115 47 -118 0 -3 -47 -5 -105 -5 -58 0 -105 3
-105 6 0 4 36 92 85 208 9 21 20 35 24 30 4 -5 28 -59 54 -121z"/>
<path d="M5600 4044 c-163 -218 -181 -250 -166 -308 l6 -26 185 0 185 0 0 -70
0 -71 78 3 77 3 3 68 3 67 54 0 55 0 0 65 0 65 -55 0 -55 0 0 65 0 65 -80 0
-80 0 0 -65 0 -65 -85 0 c-54 0 -85 4 -85 11 0 6 62 96 138 200 75 104 139
194 140 199 2 6 -28 10 -77 10 l-80 0 -161 -216z"/>
<path d="M6120 4140 l0 -120 70 0 70 0 0 55 0 55 110 0 c62 0 110 -4 110 -9 0
-6 -52 -127 -115 -271 -63 -143 -115 -265 -115 -270 0 -6 35 -10 83 -10 l84 0
73 168 c40 92 83 190 95 217 81 184 85 194 85 249 l0 56 -275 0 -275 0 0 -120z"/>
<path d="M6790 3916 l0 -346 198 0 c220 0 273 9 352 60 55 35 92 79 123 148
32 72 30 208 -5 282 -33 71 -86 123 -167 163 l-65 32 -218 3 -218 3 0 -345z
m385 203 c95 -26 145 -97 145 -206 0 -83 -25 -133 -88 -178 -42 -29 -49 -30
-163 -34 l-119 -3 0 216 0 216 93 0 c50 0 110 -5 132 -11z"/>
<path d="M7822 4248 c-6 -6 -12 -16 -12 -20 0 -7 -40 -98 -115 -263 -87 -192
-165 -373 -165 -383 0 -8 24 -12 76 -12 l75 0 31 68 c18 37 36 70 42 75 14 9
274 7 301 -3 12 -4 30 -32 45 -71 l26 -64 82 -3 c48 -2 82 1 82 7 0 5 -31 79
-69 163 -38 84 -105 234 -149 333 l-80 180 -79 3 c-52 2 -82 -1 -91 -10z m107
-195 c7 -21 26 -67 41 -103 51 -120 57 -110 -65 -110 -82 0 -106 3 -102 13 13
29 80 194 88 215 12 32 23 27 38 -15z"/>
<path d="M8294 4246 c-3 -7 -4 -35 -2 -62 l3 -49 110 -5 110 -5 3 -277 2 -278
80 0 80 0 0 280 0 280 110 0 110 0 0 65 0 65 -300 0 c-246 0 -302 -2 -306 -14z"/>
<path d="M9177 4203 c-14 -32 -74 -166 -132 -298 -59 -132 -116 -259 -126
-282 -10 -23 -19 -45 -19 -48 0 -3 37 -5 82 -3 l82 3 26 65 26 65 60 8 c32 4
106 6 162 5 l104 -3 31 -72 31 -73 83 0 c46 0 83 2 83 4 0 2 -18 44 -41 93
-22 48 -49 108 -59 133 -10 25 -32 74 -48 110 -85 184 -140 307 -146 328 -7
20 -13 22 -90 22 l-82 0 -27 -57z m164 -241 l47 -117 -100 -3 c-55 -1 -103 0
-106 2 -2 3 6 31 19 63 47 119 80 186 87 179 3 -4 28 -60 53 -124z"/>
<path d="M3800 3295 c0 -22 4 -26 23 -23 14 2 22 10 22 23 0 13 -8 21 -22 23
-19 3 -23 -1 -23 -23z"/>
<path d="M6230 3215 c0 -58 -3 -105 -6 -105 -3 0 -19 11 -36 25 -16 13 -46 30
-68 36 -55 17 -131 -12 -173 -64 -42 -51 -50 -137 -18 -194 55 -97 181 -123
260 -53 37 33 45 32 46 -7 0 -15 6 -28 13 -31 9 -3 12 51 12 247 0 216 -2 251
-15 251 -12 0 -15 -18 -15 -105z m-81 -83 c48 -24 70 -58 77 -118 12 -96 -46
-164 -140 -164 -26 0 -58 7 -71 16 -14 9 -25 12 -25 8 -2 -28 -49 88 -50 120
0 55 43 131 83 144 49 15 89 14 126 -6z"/>
<path d="M4723 3288 c-24 -5 -43 -13 -43 -19 0 -5 -6 -9 -14 -9 -7 0 -19 -6
-25 -14 -17 -21 6 -32 27 -13 22 19 86 37 134 37 76 0 126 -78 95 -151 -8 -19
-69 -88 -136 -154 -66 -66 -121 -125 -121 -132 0 -10 37 -13 160 -13 136 0
160 2 160 15 0 13 -21 15 -130 15 -71 0 -130 3 -130 7 0 3 49 57 110 119 116
118 130 141 130 205 0 50 -37 92 -96 108 -45 13 -63 12 -121 -1z"/>
<path d="M5263 3258 c-12 -17 -43 -57 -68 -87 -106 -131 -155 -196 -155 -208
0 -10 32 -13 135 -13 l135 0 0 -66 c0 -57 2 -65 18 -62 14 3 18 16 20 66 l3
62 50 0 c36 0 49 4 46 13 -2 7 -22 12 -50 12 l-47 0 0 58 c0 50 -2 57 -20 57
-18 0 -20 -7 -20 -55 l0 -55 -115 0 c-63 0 -115 3 -115 6 0 4 8 14 18 23 14
14 33 36 94 116 39 51 105 133 115 143 17 18 16 22 -4 22 -10 0 -28 -15 -40
-32z"/>
<path d="M5492 3235 c4 -67 21 -77 28 -15 l5 45 124 3 c86 2 127 -1 132 -9 4
-6 -9 -46 -28 -88 -72 -158 -81 -178 -114 -246 -52 -111 -51 -105 -28 -105 12
0 23 10 30 28 6 15 19 45 29 67 112 242 160 352 160 364 0 8 -52 11 -170 11
l-171 0 3 -55z"/>
<path d="M3998 3213 c-2 -29 -8 -39 -23 -41 -43 -7 -50 -15 -15 -21 l35 -6 5
-136 c6 -151 14 -172 71 -184 38 -9 89 3 89 20 0 11 -6 12 -29 4 -42 -15 -82
6 -93 49 -5 18 -7 81 -6 140 l3 107 53 3 c28 2 52 7 52 12 0 5 -24 10 -52 12
l-53 3 -3 38 c-4 50 -30 50 -34 0z"/>
<path d="M6830 3210 c0 -38 -2 -40 -30 -40 -16 0 -30 -4 -30 -10 0 -5 14 -10
30 -10 17 0 30 -5 31 -12 0 -7 0 -60 0 -118 0 -119 13 -168 49 -188 28 -15 97
-10 114 8 8 10 2 11 -30 6 -33 -5 -47 -2 -68 15 -26 20 -26 22 -26 155 l0 134
55 0 c30 0 55 5 55 10 0 6 -24 10 -54 10 -59 0 -71 11 -60 56 5 20 2 24 -15
24 -18 0 -21 -6 -21 -40z"/>
<path d="M4312 3159 c-29 -11 -52 -25 -50 -31 4 -12 8 -11 55 8 24 11 55 14
87 11 63 -6 96 -39 96 -98 l0 -39 -86 0 c-96 0 -145 -16 -162 -55 -16 -35 5
-93 43 -117 40 -25 124 -20 165 10 36 27 38 27 42 0 2 -12 9 -23 16 -26 17 -5
17 253 -1 287 -32 64 -118 84 -205 50z m188 -208 c0 -50 -30 -88 -82 -102 -80
-21 -141 10 -141 73 0 53 30 68 134 68 l89 0 0 -39z"/>
<path d="M6480 3166 c-50 -16 -81 -36 -75 -46 3 -5 26 1 52 12 107 47 194 -4
184 -109 -1 -7 -35 -12 -102 -15 -96 -3 -102 -4 -125 -31 -87 -102 80 -213
190 -126 29 23 50 17 40 -13 -5 -16 -2 -19 13 -16 16 3 18 15 18 128 0 155
-10 184 -72 211 -50 21 -72 22 -123 5z m161 -193 c4 -67 -20 -104 -78 -124
-45 -15 -109 -3 -128 24 -21 30 -19 73 5 97 17 17 33 20 110 20 79 0 90 -2 91
-17z"/>
<path d="M7161 3164 c-52 -16 -75 -34 -63 -46 6 -6 23 -1 43 11 40 24 96 27
143 7 36 -15 43 -28 48 -86 l3 -35 -101 -5 c-81 -4 -106 -9 -123 -24 -57 -52
-24 -150 56 -162 57 -8 106 2 128 26 25 28 45 25 45 -6 0 -16 5 -24 13 -22 8
3 13 40 15 127 3 149 -7 179 -74 210 -50 24 -71 25 -133 5z m171 -219 c-4 -49
-42 -92 -87 -101 -50 -9 -113 12 -129 44 -15 31 -12 48 17 80 20 21 28 22 112
20 l90 -3 -3 -40z"/>
<path d="M7733 3156 c-38 -18 -58 -35 -77 -68 -32 -55 -35 -127 -8 -166 10
-15 21 -34 25 -43 3 -9 26 -27 52 -39 62 -30 146 -24 183 13 31 32 20 58 -12
28 -55 -52 -138 -46 -197 15 -26 27 -33 43 -37 87 -3 45 0 60 21 94 47 74 141
93 209 43 30 -22 58 -19 38 5 -16 19 -96 55 -122 55 -13 0 -46 -11 -75 -24z"/>
<path d="M8141 3168 c-48 -16 -98 -70 -111 -120 -19 -68 -5 -121 44 -171 59
-62 126 -73 203 -36 91 44 124 156 73 246 -38 68 -135 105 -209 81z m146 -50
c74 -69 69 -193 -9 -251 -35 -26 -121 -26 -156 0 -51 38 -67 70 -66 133 1 66
22 102 77 133 43 24 120 17 154 -15z"/>
<path d="M8606 3160 c-21 -11 -44 -24 -52 -30 -17 -15 -34 -4 -34 21 0 10 -7
19 -16 19 -13 0 -15 -23 -13 -175 2 -139 5 -175 16 -175 10 0 13 21 13 88 1
123 12 168 49 206 28 27 38 31 84 31 44 -1 59 -6 83 -28 29 -27 29 -28 32
-162 3 -102 7 -135 16 -135 10 0 15 32 18 124 6 141 16 168 76 193 49 20 109
10 138 -24 27 -32 34 -70 34 -195 0 -80 3 -98 15 -98 12 0 15 19 15 113 0 169
-20 213 -106 237 -38 11 -51 10 -89 -4 -25 -10 -53 -28 -64 -42 -23 -29 -38
-31 -46 -5 -6 21 -80 61 -111 61 -10 -1 -36 -9 -58 -20z"/>
<path d="M3810 3005 c0 -91 0 -168 -1 -172 0 -5 7 -9 15 -11 14 -3 16 19 16
172 0 150 -2 176 -15 176 -13 0 -15 -25 -15 -165z"/>
<path d="M7494 2865 c-8 -21 3 -45 20 -45 20 0 29 16 21 40 -7 23 -33 27 -41
5z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
